import { Component, OnInit } from '@angular/core';
import { CommonService } from '../services/common.service';
import { SnackService } from '../services/snackbar.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-update-password',
  templateUrl: './update-password.component.html',
  styleUrls: ['./update-password.component.css']
})
export class UpdatePasswordComponent implements OnInit {
  token: any = ""
  constructor(
    private commonService: CommonService,
    private snackService: SnackService,
    private formbuilder: FormBuilder,
    private route: ActivatedRoute) {
    this.token = this.route.snapshot.queryParamMap.get('tok');
  }
  passwordForm: FormGroup
  ngOnInit() {
    this.createform()
  }

  createform() {
    this.passwordForm = this.formbuilder.group({
      password: ['', [Validators.required]],
      confirmPassword: ['', Validators.required]
    });
  }

  submitform() {
    if (this.passwordForm.invalid) return false;
    if (this.passwordForm.value.password !== this.passwordForm.value.confirmPassword)
      return this.snackService.openSnackBar('Password and Confirm Password not matched', null)
    this.commonService.callApiFunction('/api/user/resetPasswordLink/update', 'post', { token: this.token, password: this.passwordForm.value.password })
      .subscribe(data => {
        console.log("dataaaa", data)
        if (data['status'] == true) {
          return this.snackService.openSnackBar('Password updated successfully', null)
        } else {
          return this.snackService.openSnackBar('Something Went Wrong', null)
        }
      },
        error => {
          return this.snackService.openSnackBar(error.message, null)
        })
  }

}
