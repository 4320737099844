import { Component, OnInit, OnDestroy } from '@angular/core';
import { CommonService } from '../../../app/services/common.service'
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

var $: any;
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit, OnDestroy {
  cartCount: any = 0
  token: boolean = false
  userData : any = {userName: "", picture: ""}
  subscriber$;
  constructor(private router: Router, private commonService: CommonService) {
    this.commonService.getCartCounter()
      .subscribe(res => {this.cartCount = res});

    this.commonService.gettoken()
      .subscribe(res => {
        if (res) {
          this.token = true
        }
      });
  }

  ngOnInit() {
    this.getCartCount()
    this.getProfile()
  }
  hidemenu() {
    $('.navbar-collapse').removeClass('show');
  }

  getCartCount() {
    this.commonService.callApiFunction('/inventory/order/cart-count', 'get', null)
      .subscribe(data => {
        this.cartCount = data['data']
        this.commonService.updateCartCounter(data['data']);
      },
        error => {
          this.cartCount = 0
        })
  }

  getProfile() {
    this.commonService.callApiFunction('/api/user', 'get', null)
      .subscribe(data => {
        this.userData = {userName:data['data']['name']['userName'] ? data['data']['name']['userName'] : "", picture : data['data']['picture']}
        this.commonService.updateProfile({userName:data['data']['name']['userName'] ? data['data']['name']['userName'] : "", picture : data['data']['picture']});
      },
        error => {
          this.userData = this.userData
        })
  }


  logout() {
    this.commonService.callApiFunction('/api/auth/logout', 'post', { deviceId: 'string' })
      .subscribe(data => {
        this.commonService.updateToken(null)
        localStorage.clear()
        this.token = false
        this.router.navigate(['/login'])
      },
        error => {
          this.commonService.updateToken(null)
          localStorage.clear()
          this.token = false
          this.router.navigate(['/login'])
        })
  }

  sendSupportEmail(){

  }

  ngOnDestroy() {
    this.subscriber$.unsubscribe();
  }

}
