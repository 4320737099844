import { Component, OnInit } from '@angular/core';
import { CommonService } from '../services/common.service';
import { SnackService } from '../services/snackbar.service';

@Component({
  selector: 'app-forgotpassword',
  templateUrl: './forgotpassword.component.html',
  styleUrls: ['./forgotpassword.component.css']
})
export class ForgotpasswordComponent implements OnInit {
  email: any = ""
  regex = /[a-z0-9!#$%&'*+=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/g;
  constructor(private commonService: CommonService, private snackService: SnackService) { }

  ngOnInit() {
  }

  setEmail(event) {
    this.email = event.target.value
  }

  sendForgotUrl() {
    alert(this.regex.test(this.email))
    if (this.email =="" || this.regex.test(this.email))
      return this.snackService.openSnackBar('Please Provide a valid email', null)
    this.commonService.callApiFunction('/api/user/resetPasswordLink?email=' + this.email, 'get', null)
      .subscribe(data => {
        console.log("dataaaa", data)
        if (data['status'] == true) {
          return this.snackService.openSnackBar(data['message'], null)
        } else {
          return this.snackService.openSnackBar(data['message'], null)
        }
      },
        error => {
          return this.snackService.openSnackBar(error.message, null)
        })
  }

}
