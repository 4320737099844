import { Component, OnInit } from '@angular/core';
import { CommonService } from '../services/common.service';
import { SnackService } from '../services/snackbar.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-order-details',
  templateUrl: './order-details.component.html',
  styleUrls: ['./order-details.component.css']
})
export class OrderDetailsComponent implements OnInit {
  orderDetail: any = {}
  activatedRoute: any;
  id: any = ""
  constructor(private route: ActivatedRoute, private commonService: CommonService, private snackService: SnackService) {
    this.id = this.route.snapshot.paramMap.get('id'); 
  }

  ngOnInit() {
    this.getOrderDetails()
  }

  getOrderDetails() {
    this.commonService.callApiFunction('/inventory/order/' + this.id, 'get', null)
      .subscribe(result => {
        console.log("dataaaaaaaaaa", result['data'])
        this.orderDetail = result['data']
      }, error => {
        this.orderDetail = {}
      })
  }

}
