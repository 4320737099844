import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2'
import { CommonService } from '../services/common.service';
import { SnackService } from '../services/snackbar.service';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';

declare const $
@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.css']
})
export class PaymentComponent implements OnInit {
  cards: any = []
  errorMessage: any = ""
  isEmpty: boolean = false
  threeCard: boolean;
  constructor(private commonService: CommonService, private snackService: SnackService, private formbuilder: FormBuilder) { }

  cardForm: FormGroup = this.formbuilder.group({
    holder: new FormControl('', [Validators.required]),
    number: new FormControl('', [Validators.required, Validators.max(16)]),
    expire: this.formbuilder.group({
      month: new FormControl('', [Validators.required, Validators.max(2)]),
      year: new FormControl('', [Validators.required]),
    })
  })
  month: any = ""
  year: any = ""
  ngOnInit() {
    this.getCards()
  }

  resetForm(){
    this.cardForm.reset()
  }

  getCards() {
    this.commonService.callApiFunction('/payment/card', 'get', null)
      .subscribe(data => {
        if (data['status'] == true && data['data'].length) {
          if(data['data'].length>=3){
            this.threeCard = true
          }else{
            this.threeCard = false
          }
          this.cards = data['data']
        } else {
          this.isEmpty = true
          this.cards = []
        }
      }, error => {
        this.isEmpty = true
        this.cards = []
      })
  }

  selectMonth(event) {
    this.cardForm.patchValue({
      expire: {
        month: event.target.value
      }
    })
  }

  selectYear(event) {
    this.year = event.target.value
    this.cardForm.patchValue({
      expire: {
        year: event.target.value
      }
    })
  }

  addCard() {
    this.commonService.callApiFunction('/payment/card', 'post', this.cardForm.value)
      .subscribe(data => {
        $('#addcard').modal('hide')
        this.snackService.openSnackBar('Card Added Successfully', null)
        this.getCards()
      }, error => {
        this.errorMessage = 'Please provide Valid data'
      })
  }

  deleteCard(id) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this imaginary file!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        this.commonService.callApiFunction('/payment/card/' + id, 'delete', {})
          .subscribe(data => {
            Swal.fire(
              'Deleted!',
              'Deleted',
              'success'
            )
            this.getCards()
          }, error => {
            Swal.fire(
              'Deleted!',
              error.message,
              'success'
            )
          })
      }
    })
  }

}
