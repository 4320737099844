import { Component, OnInit } from '@angular/core';
import { CommonService } from '../services/common.service';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.css']
})
export class FaqComponent implements OnInit {
  questions : any = []
  constructor(private commonService: CommonService) { }

  ngOnInit() {
    this.getFaq()
  }

  getFaq(){
    if(localStorage.getItem('atlas$%^token')){
      this.commonService.callApiFunction('/settings/faq', 'get', null)
      .subscribe(data => {
        this.questions = data['data']
      }, error => {
        this.questions = []
      })
    }else{
      this.commonService.callApiFunction('/settings/faq/faq-website', 'get', null)
      .subscribe(data => {
        this.questions = data['data']
      }, error => {
        this.questions = []
      })
    }
  }

}
