import { Component, OnInit, OnDestroy, AfterViewChecked } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { ViewportScroller } from '@angular/common';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CommonService } from '../services/common.service';
import { SnackService } from '../services/snackbar.service';
import { environment } from '../../environments/environment'
@Component({
  selector: 'app-contactus',
  templateUrl: './contactus.component.html',
  styleUrls: ['./contactus.component.css']
})
export class ContactusComponent implements OnInit, AfterViewChecked {

  supportForm: FormGroup;
  fragment: any;
  constructor(private route: ActivatedRoute, private vps: ViewportScroller, private formbuilder: FormBuilder, private commonService: CommonService, private snackService: SnackService) { }

  ngAfterViewChecked(): void {
    try {
      if (this.fragment) {
        this.vps.scrollToAnchor(this.fragment);
      }
    } catch (e) {
      console.log(e);
    }
  }

  ngOnInit() {
    this.route.fragment.subscribe(fragment => { this.fragment = fragment; });
    this.supportForm = this.formbuilder.group({
      name: ['', [Validators.required]],
      email: ['', Validators.required],
      phone: ['', Validators.required],
      subject: ['', Validators.required],
      suggestion: ['', Validators.required]
    });
  }

  sendSupportEmail() {
    let tNumber = Math.floor(Math.random() * 6 + 1)
    if (this.supportForm.invalid)
      return this.snackService.openSnackBar('Please fill all fields correctlly!', null)
    this.commonService.callApiFunction('/notifications/email', 'post', {
      to: [environment.SUPPORT_EMAIL],
      subject: 'New support ticket raised: Ticket No- '+tNumber,
      text: 'hi',
      html: '<p><strong>Hi</strong> Team AppsRhino</p>' +
        '<p>One new support ticket has been raised. Further Details are as follows</p>'+
        '<p>Ticket No- '+tNumber+'</p>'+
        '<p>Name : ' + this.supportForm.value.name + '</p>' +
        '<p>Email :  ' + this.supportForm.value.email + '</p>' +
        '<p>Subject :  ' + this.supportForm.value.subject + '</p>' +
        '<p>Query:  ' + this.supportForm.value.suggestion + '</p>' +
        '<h4>Regards</h4>' +
        '<p>' + this.supportForm.value.name + '</p>'
    })
      .subscribe(data => {
        return this.snackService.openSnackBar('Email has sent to support, they will resolve you concern as soon as possible, Thanks to reach us!', null)
      },
        error => {
          return this.snackService.openSnackBar('Email has sent to support, they will resolve you concern as soon as possible, Thanks to reach us!', null)
        })
  }

}