import { Component, OnInit, OnDestroy, AfterViewChecked } from '@angular/core';
import { SingletonService } from '../singleton.service';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { ViewportScroller } from '@angular/common';
declare var $: any;

@Component({
  selector: 'app-services-list',
  templateUrl: './services-list.component.html',
  styleUrls: ['./services-list.component.css']
})
export class ServicesListComponent implements OnInit, AfterViewChecked {

  fragment: any;
  constructor(private common: SingletonService, private route: ActivatedRoute, private vps: ViewportScroller) { }

  ngAfterViewChecked(): void {
    try {
      if (this.fragment) {
        this.vps.scrollToAnchor(this.fragment);
      }
    } catch (e) {
      console.log(e);
    }
  }

  ngOnInit() {
    this.route.fragment.subscribe(fragment => { this.fragment = fragment; });
  }

}
