import { Injectable } from '@angular/core';
import { Observable, throwError, from, BehaviorSubject } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment'

@Injectable({
  providedIn: 'root'
})

export class CommonService {
  BASE_URL = environment.BASE_URL

  cartCounter$: BehaviorSubject<any> = new BehaviorSubject(0);

  getCartCounter() {
    return this.cartCounter$.asObservable();
  }

  updateCartCounter(val) {
    this.cartCounter$.next(val);
  }

  profile$: BehaviorSubject<any> = new BehaviorSubject({userName: "", picture: ""});

  getProfile() {
    return this.profile$.asObservable();
  }

  updateProfile(val) {
    this.profile$.next(val);
  }

  token$: BehaviorSubject<any> = new BehaviorSubject(!!localStorage.getItem('atlas$%^token'));

  gettoken() {
    return this.token$.asObservable();
  }

  updateToken(val) {
    this.token$.next(val);
  }

  constructor(private http: HttpClient, private router: Router) { }
  callApiFunction(url, method, data = {}, headers = { 'Content-type': 'application/json' }): Observable<JSON> {
    if (method.toLowerCase() == 'post') {
      console.log("asd");
      return this.http.post<any>(this.BASE_URL + url, data).pipe(catchError(this.errorHandler))
    } else if (method.toLowerCase() == 'get') {
      return this.http.get<any>(this.BASE_URL + url).pipe(catchError(this.errorHandler))
    } else if (method.toLowerCase() == 'put') {
      return this.http.put<any>(this.BASE_URL + url, data).pipe(catchError(this.errorHandler))
    } else if (method.toLowerCase() == 'delete') {
      return this.http.delete<any>(this.BASE_URL + url, data).pipe(catchError(this.errorHandler))
    }
  }
  errorHandler(error: HttpErrorResponse) {
    return throwError(error.error || new Error("Internal server error"))
  }

  /**UPLOAD FILE SERVICE FUNCTION START */

  uploadFile(fileData, url): Observable<JSON> {
    return this.http.post<JSON>(this.BASE_URL + url, fileData).pipe(
      catchError(this.uploadErrorHandler)
    )
  }

  uploadErrorHandler(error: HttpErrorResponse) {
    if (error.statusText == 'Unknown Error')
      return throwError(new Error("File size exceeded limit!"))
    else
      return throwError(error.error || new Error("Internal server error"))
  }

  /**UPLOAD FILE SERVICE FUNCTION END */

  getPager(totalItems: number, currentPage: number = 1, pageSize: number = 10) {
    // if (!navigator.onLine) {
    //   this.openSnackBar({ message: 'Please connect to internet' });
    //   return;
    // }
    // calculate total pages
    const totalPages = Math.ceil(totalItems / pageSize);
    // ensure current page isn't out of range
    if (currentPage < 1) {
      currentPage = 1;
    } else if (currentPage > totalPages) {
      currentPage = totalPages;
    }

    let startPage: number;
    let endPage: number;
    if (totalPages <= 10) {
      // less than 10 total pages so show all
      startPage = 1;
      endPage = totalPages;
    } else {
      // more than 10 total pages so calculate start and end pages
      if (currentPage <= 6) {
        startPage = 1;
        endPage = 10;
      } else if (currentPage + 4 >= totalPages) {
        startPage = totalPages - 9;
        endPage = totalPages;
      } else {
        startPage = currentPage - 5;
        endPage = currentPage + 4;
      }
    }

    // calculate start and end item indexes
    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);

    // create an array of pages to ng-repeat in the pager control
    const pages = Array.from(Array((endPage + 1) - startPage).keys()).map(i => startPage + i);

    // return object with all pager properties required by the view
    return {
      totalItems,
      currentPage,
      pageSize,
      totalPages,
      startPage,
      endPage,
      startIndex,
      endIndex,
      pages
    };
  }

}