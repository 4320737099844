import { Component, OnInit } from '@angular/core';
import { CommonService } from '../../app/services/common.service'
import { SnackService } from '../../app/services/snackbar.service'
import { MessageService } from '../../app/services/message.service'
import { PageEvent } from '@angular/material';

declare const $: any
@Component({
  selector: 'app-price',
  templateUrl: './price.component.html',
  styleUrls: ['./price.component.css']
})
export class PriceComponent implements OnInit {
  token: boolean = false
  productId: any = ""
  cartData: any = {}
  quantity: any = 1
  homeData: any = []
  services: any = []
  serviceArray: any = []
  errorMessage: any = ""
  isService: boolean = false
  currentPage: any = 1
  numPerPage: any = 10
  pager = {
    totalItems: 0,
    currentPage: 1,
    pageSize: 10,
    totalPages: 10,
    startPage: 1,
    endPage: 10,
    startIndex: 1,
    endIndex: 10,
    pages: []
  };
  length = 100;
  pageSize = 10;
  pageSizeOptions: number[] = [5, 10, 25, 100];
  searchObj = {
    search: '',
    pageNumber: 1,
    noOfRecord: 10
  };
  pageEvent: PageEvent;
  constructor(
    private commonService: CommonService,
    private snackService: SnackService,
    private messageService: MessageService
  ) {
    this.commonService.gettoken()
      .subscribe(res => {
        if (res) {
          this.token = true
        }
      })
  }
  slides = [
    { img: "assets/images/product/img1.jpg", productName: 'Product 1', price: '€ 450' },
    { img: "assets/images/product/img2.jpg", productName: 'Product 2', price: '€ 420' },
    { img: "assets/images/product/img3.jpg", productName: 'Product 3', price: '€ 430' },
    { img: "assets/images/product/img4.jpg", productName: 'Product 4', price: '€ 460' },
    { img: "assets/images/product/img5.jpg", productName: 'Product 4', price: '€ 460' },
    { img: "assets/images/product/img4.jpg", productName: 'Product 4', price: '€ 460' },
  ];

  slideConfig =
    {
      "slidesToShow": 5, "slidesToScroll": 5, autoplay: true, autoplaySpeed: 3000, margin: '10',
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2
          }
        },
        {
          breakpoint: 360,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2
          }
        }
        // You can unslick at a given breakpoint now by adding:
        // settings: "unslick"
        // instead of a settings object
      ]
    };
  slickInit(e) {
    //console.log('slick initialized');
  }
  breakpoint(e) {
    //console.log('breakpoint');
  }

  afterChange(e) {
    //console.log('afterChange');
  }

  beforeChange(e) {
    //console.log('beforeChange');
  }
  ngOnInit() {
    this.getProducts()
    // if(localStorage.getItem('atlas$%^token')){
    //   this.getServices()
    // }
  }

  getProducts() {
    if (localStorage.getItem('atlas$%^token')) {
      this.commonService.callApiFunction('/inventory/category/home-screen-user', 'get', null)
        .subscribe(data => {
          if (data['status'] == true) {
            this.homeData = data['data']['result']
          } else {
            this.snackService.openSnackBar('No data found', null)
          }
        },
          error => {
            this.snackService.openSnackBar(error.message, null)
          })
    } else {
      this.commonService.callApiFunction('/inventory/category/home-screen-user-website', 'get', null)
        .subscribe(data => {
          if (data['status'] == true) {
            this.homeData = data['data']['result']
          } else {
            this.snackService.openSnackBar('No data found', null)
          }
        },
          error => {
            this.snackService.openSnackBar(error.message, null)
          })
    }
  }


  // setPage(page: PageEvent): PageEvent {
  //   this.searchObj.pageNumber = Number(page.pageIndex + 1);
  //   this.searchObj.noOfRecord = Number(page.pageSize);
  //   this.getServices();
  //   return;
  // }

  getServices(id) {
    this.commonService.callApiFunction('/inventory/product/getServicesProduct?productId=' + id, 'get', null)
      .subscribe(data => {
        if (data['status'] == true) {
          this.services = data['data']['service']
          // this.pager = this.commonService.getPager(Number(data['totalRecords']), Number(data['currentPage']));
          // this.length = this.pager.totalItems;
          // this.pageSize = this.pager.pageSize;
        } else {
          this.snackService.openSnackBar('No data found', null)
        }
      },
        error => {
          this.snackService.openSnackBar(error.message, null)
        })
  }

  getProductId(productId) {
    this.productId = productId
    this.getServices(productId)
  }

  getQuantity(quantity) {
    if (quantity < 1)
      this.quantity = 1
    this.quantity = quantity
  }

  getService(serviceId, price) {
    let data = {
      _service: serviceId,
      price: price
    }
    const index = this.serviceArray.findIndex(serv => serv._service === serviceId);
    if (index > -1) {
      this.serviceArray = this.serviceArray.filter(serv => serv._service !== serviceId)
    } else {
      this.serviceArray.push(data)
    }
    if(this.serviceArray.length){
      this.errorMessage = ""
    }
  }

  addToCart() {
    this.cartData = {
      item: {
        productId: this.productId,
        service: this.serviceArray,
        quantity: this.quantity
      }
    }
    if (this.serviceArray.length <= 0) return this.errorMessage = "Please select a service first to proceed"
    this.commonService.callApiFunction('/inventory/order/add-item', 'post', this.cartData)
      .subscribe(data => {
        if (data['status'] == true) {
          this.isService = false
          $("#selectcategory").modal('hide')
          this.serviceArray = []
          this.commonService.updateCartCounter(data['data'].items.length);
          this.snackService.openSnackBar('Item added to cart Successfully', null)
        } else {
          this.snackService.openSnackBar('Something Went Wrong', null)
        }
      },
        error => {
          this.snackService.openSnackBar(error.message, null)
        })
  }

  hideMOdal() {
    $('#selectcategory').modal('hide');
  }

}
