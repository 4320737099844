import { Component, OnInit } from '@angular/core';
import { CommonService } from '../../app/services/common.service'
import { SnackService } from '../../app/services/snackbar.service'
import { from } from 'rxjs';
import { MessageService } from '../services/message.service';

@Component({
  selector: 'app-order-summary',
  templateUrl: './order-summary.component.html',
  styleUrls: ['./order-summary.component.css']
})
export class OrderSummaryComponent implements OnInit {
  cartData: any = []
  currentPage: any = 1
  limit: any = 10
  cartId: any = ""
  price: any = {}
  tax: any = {}
  deliveryCharge: any = {}
  isEmpty: Boolean = false
  cartCount = 0;
  constructor(
    private commonService: CommonService,
    private snackService: SnackService,
    private messageService: MessageService
  ) {
    this.commonService.getCartCounter()
      .subscribe(res => this.cartCount = res);
  }

  ngOnInit() {
    this.getCartData()
  }

  getCartData() {
    this.commonService.callApiFunction('/inventory/order/get-cart', 'get', null)
      .subscribe(data => {
        console.log("dataaa", data['data'])
        if (data && data['status'] && data['data']['items'].length) {
          this.cartId = data['data']['_id']
          this.price = data['data']['totalPrice'],
            this.tax = data['data']['tax']
          this.deliveryCharge = data['data']['deliveryCharge']
          localStorage.setItem('cartData', JSON.stringify({
            cartId: this.cartId,
            price: this.price,
            tax: this.tax,
            deliveryCharge: this.deliveryCharge
          }))
          this.cartData = data['data']['items']
        } else {
          this.isEmpty = true
          this.price = {totalAmount:0, units:'AUD'}
          this.cartData = []
        }
      },
        error => {
          this.isEmpty = true
          this.cartData = []
        })
  }

  updateCart(productId, service, quantity) {
    if (quantity < 0) {
      quantity = 0
    }
    let serviceArr = []
    for (let i = 0; i < service.length; i++) {
      serviceArr.push({ _service: service[i]._service._id, price: service[i].price })
    }
    let data = {
      item: {
        "productId": productId,
        "service": serviceArr,
        "quantity": quantity
      }
    }
    this.commonService.callApiFunction('/inventory/order/add-item', 'post', data)
      .subscribe(data => {
        this.commonService.updateCartCounter(data['data'].items.length);
        this.snackService.openSnackBar('Cart updated successfully', null)
        this.getCartData()
      }, error => {
        this.snackService.openSnackBar(error.message, null)
      })
  }

  removeCartData(productId, service, quantity) {
    let serviceArr = []
    for (let i = 0; i < service.length; i++) {
      serviceArr.push({ _service: service[i]._service._id, price: service[i].price })
    }
    let obj = {
      item: {
        "productId": productId,
        "service": serviceArr,
        "quantity": quantity
      }
    }
    this.commonService.callApiFunction('/inventory/order/remove-item', 'post', obj)
      .subscribe(data => {
        this.commonService.updateCartCounter(this.cartCount - 1);
        this.snackService.openSnackBar('Item removed successfully', null)
        this.getCartData()
      }, error => {
        this.snackService.openSnackBar(error.message, null)
      })
  }

  checkoutProceed() {
    this.messageService.sendMessage({ cartId: this.cartId, price: this.price, deliveryCharge: this.deliveryCharge, tax: this.tax })
  }

  clearMessages(): void {
    // clear messages
    this.messageService.clearMessages();
  }

}
