import { Component, OnInit, ViewChild } from '@angular/core';
import { GooglePlaceDirective } from "node_modules/ngx-google-places-autocomplete/ngx-google-places-autocomplete.directive";
import { SingletonService } from '../singleton.service';
import { from } from 'rxjs';
import Swal from 'sweetalert2'
import { CommonService } from '../services/common.service';
import { SnackService } from '../services/snackbar.service';
import { Router } from '@angular/router';
declare var $: any;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  address: any = []
  constructor(private commonService: CommonService, private snackBar: SnackService, private router: Router) { }
  name: any = ''
  ngOnInit() {
    this.getShopAddress()
  }

  getShopAddress() {
    this.commonService.callApiFunction('/inventory/charge/getShopAddress', 'get', null)
      .subscribe(data => {
        this.address = data['data']['address'][0]['geo'] ? data['data']['address'][0]['geo'] : [0, 0]
      }, error => {
        this.address = [0, 0]
      })
  }

  deg2rad(deg) {
    return deg * (Math.PI / 180)
  }

  getDistanceFromLatLonInKm(lat1, lon1, lat2, lon2) {
    var R = 6371; // Radius of the earth in kilometers
    var dLat = this.deg2rad(lat2 - lat1); // deg2rad below
    var dLon = this.deg2rad(lon2 - lon1);
    var a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(this.deg2rad(lat1)) * Math.cos(this.deg2rad(lat2)) *
      Math.sin(dLon / 2) * Math.sin(dLon / 2);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = R * c; // Distance in KM
    return d;
  }

  handleAddressChange(e) {
    let distance = this.getDistanceFromLatLonInKm(this.address[1], this.address[0], e['geo'][1], e['geo'][0])
    if (distance <= 50) {
      Swal.fire(
        'Success!',
        'Service is available in your area, Login and enjoy services',
        'success'
      )
      this.router.navigate(['/login'])
    } else {
      Swal.fire(
        'Sorry',
        'Service is not available in your area right now',
      )
    }
  }

  testimonial = [
    { name: 'Laura ', position: '', image: 'assets/images/client-image.jpg', description: '<p>I had my wedding dress dry cleaned by appsRhino Laundry a couple of weeks ago. appsRhino Laundry staff were very professional, and the pick up and drop off service was great! My dress and veil were beautifully presented in a box and I was amazed that you were able to restore it to a almost brand new condition. I was very happy with how it turned out!!</p> <p>I would definitely recommend appsRhino Laundry Dry Cleaners to friends.</p>', date: '14th July 2014' },
    { name: 'John & Rosemary Wallace, ', position: 'Ocean Reef, Western Australia', image: 'assets/images/client-image.jpg', description: '<p>My Wife and Myself had occasion to have some dry cleaning to be done by appsRhino Laundry Dry Cleaners. This work was under taken personally by Mr Minh Cao.</p> <p>The pieces of work were a christening gown set, which included an ages white gown and petticoat plus another gown, each in a fine cotton fabric with much cotton lacework and fine needlework.</p><p>We had personally used it for a christening 45 years ago and can verify it is a little over 130 years of age. Since last worn it has hung in a wardrobe and had developed “aging stains” and excessive brown discolouration.</p><p>The garments were taken to appsRhino Laundry Dry Cleaners, who were recommended to us, and after collecting it after 4 days, we were absolutely amazed at its restoration. The pleasure and satisfaction that we got from seeing that restored garments exceeded our expectations.</p><p>It is without hesitation that we recommend appsRhino Laundry Dry Cleaners, in particular Mr Minh Cao for the purpose of dry cleaning and restoration of ages and delicate fabrics.</p>', date: '15th November 2013' },
    { name: 'P A (Abbie) Durack Clancy', position: 'Member of the Royal WA Historical Society', image: 'assets/images/client-image.jpg', description: '<p>Thank you for the way you have so carefully restored the gown that my grandmother Bessie Ida Muriel (Johnstone) wore on the day of her wedding to Michael Patrick Durack of Argyle Station Kimberley. The gown was largely hand-sewn and now is almost 100 years old as my grandparents were married in Adelaide September 1909. (They went on to produce six children, four sons and two daughters – the writer Mary and the artist Elizabeth Durack, my mother).</p><p>Other family members also have been delighted to see the glorious gown and have observed how you have been able to bring it back to its original ivory colour and invisibly mend a few holes in the lace.</p><p>I had heard about your restoration work from theatre friends who mentioned that you use similar methods to those used by the Paris Museum.</p><p>I am pleased to report the gown was worn recently during a charity fund-raising event where it was widely admired and applauded. I shall certainly recommend appsRhino Laundry Dry Cleaners to anyone seeking preservation or restoration work done on precious garments.</p>', date: '6th October 2003' },
  ]
  // testimonialContent = JSON.stringify(this.testimonial)
  slideConfig = {
    "slidesToShow": 1, "slidesToScroll": 1, autoplay: true, autoplaySpeed: 3000, margin: '10',
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 360,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  }

}
