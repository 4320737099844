import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-hats-akubra',
  templateUrl: './hats-akubra.component.html',
  styleUrls: ['./hats-akubra.component.css']
})
export class HatsAkubraComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
