import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-leather-suedes',
  templateUrl: './leather-suedes.component.html',
  styleUrls: ['./leather-suedes.component.css']
})
export class LeatherSuedesComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
