import { Component, OnInit } from '@angular/core';
import { CommonService } from '../services/common.service';
import { SnackService } from '../services/snackbar.service';
import { Router } from '@angular/router';

declare const $
@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {
  profile: any = {}
  showOtp = false;
  uploadedImage: any = ''
  phoneOrEmail: any = ""
  newEmail: any = ""
  errorMessage1: any = ""
  errorMessage2: any = ""
  otp: any = ""
  isShow: boolean;
  constructor(private router: Router, private commonService: CommonService, private snackService: SnackService) { }

  ngOnInit() {
    this.getProfile()
  }

  getProfile() {
    this.commonService.callApiFunction('/api/user', 'get', null)
      .subscribe(data => {
        this.profile = data['data']
        this.commonService.updateProfile({ userName: data['data']['name']['userName'] ? data['data']['name']['userName'] : "", picture: data['data']['picture'] ? data['data']['picture'] : "" })
        this.phoneOrEmail = this.profile.contact['email']
      }, error => {
        this.snackService.openSnackBar(error.message, null)
      })
  }

  uploadFile(event) {
    let image = <File>event.target.files[0]
    let formData = new FormData()
    formData.append('file', image, image.name)
    this.commonService.uploadFile(formData, '/upload/')
      .subscribe(result => {
        this.profile['picture'] = result['data']
        this.snackService.openSnackBar("Image uploaded", null);
      }, error => {
        return this.snackService.openSnackBar(error.message, null);
      })
  }

  hideOtpModel() {
    this.errorMessage1 = ""
    this.errorMessage2 = ""
    this.showOtp = false
  }

  updateUserData(event) {
    if (event.target.name == 'userName')
      this.profile.name['userName'] = event.target.value
    else if (event.target.name == 'phone') {
      if (event.target.value.length < 10 || event.target.value.length > 12) {
        return this.snackService.openSnackBar('Phone number length should between 10 to 12 digits', null)
      }
      this.profile.contact['phone'] = event.target.value
    }
    else if (event.target.name == 'email') {
      this.profile.contact['email'] = event.target.value
    }
  }

  updateProfile() {
    this.commonService.callApiFunction('/api/user', 'put', { name: this.profile.name, contact: this.profile.contact, picture: this.profile.picture })
      .subscribe(data => {
        this.getProfile()
        this.snackService.openSnackBar('Updated Successfully', null)
      }, error => {
        this.snackService.openSnackBar(error.message, null)
      })
  }

  setEmail(event) {
    this.newEmail = event.target.value
  }

  sendVerificationEmail() {
    if (!this.phoneOrEmail || !this.newEmail) {
      this.errorMessage1 = 'Please Fill All Required Data'
    }
    this.commonService.callApiFunction('/api/user/sendOTP', 'post', { phoneOrEmail: this.phoneOrEmail, newEmail: this.newEmail, role: 'USER' })
      .subscribe(data => {
        if (data['status']) {
          this.showOtp = true
          this.errorMessage2 = ""
          this.errorMessage1 = data['message']
        } else {
          this.errorMessage2 = ""
          this.errorMessage1 = data['message']
        }
      }, error => {
        this.errorMessage2 = ""
        this.errorMessage1 = error.message
      })
  }

  setOTP(event) {
    this.otp = event.target.value
  }

  updateEmail() {
    if (!this.otp) {
      this.errorMessage1 = ""
      this.errorMessage2 = 'Please fill otp'
    }
    this.commonService.callApiFunction('/api/user/verifyOTP/' + this.otp + '/' + this.phoneOrEmail + '/' + this.newEmail, 'post', {})
      .subscribe(data => {
        $('#change-email').modal('hide')
        this.commonService.callApiFunction('/api/auth/logout', 'post', { deviceId: 'string' })
          .subscribe(data => {
            this.commonService.updateToken(null)
            localStorage.clear()
            this.router.navigate(['/login'])
          },
            error => {
              this.commonService.updateToken(null)
              localStorage.clear()
              this.router.navigate(['/login'])
            })

      }, error => {
        this.errorMessage1 = ""
        this.errorMessage2 = error.message
      })
  }

}
