import { Component, OnInit,ViewChild } from '@angular/core';
import { GooglePlaceDirective } from "node_modules/ngx-google-places-autocomplete/ngx-google-places-autocomplete.directive";
declare var $:any;

@Component({
  selector: 'app-location',
  templateUrl: './location.component.html',
  styleUrls: ['./location.component.css']
})
export class LocationComponent implements OnInit {

  @ViewChild("placesRef",{static:false}) placesRef : GooglePlaceDirective;
  handleAddressChange(e){
    
  }
  constructor() { }

  ngOnInit() {
  }

}
