import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { PriceComponent } from './price/price.component';
import { BusinessComponent } from './business/business.component';
import { ProfileComponent } from './profile/profile.component';
import { AddressbookComponent } from './addressbook/addressbook.component';
import { MyordersComponent } from './myorders/myorders.component';
import { PromolistingComponent } from './promolisting/promolisting.component';
import { SharerefferalcodeComponent } from './sharerefferalcode/sharerefferalcode.component';
import { OrderDetailsComponent } from './order-details/order-details.component';
import { LoginComponent } from './login/login.component';
import { SignupComponent } from './signup/signup.component';
import { ForgotpasswordComponent } from './forgotpassword/forgotpassword.component';
import { UpdatePasswordComponent } from './update-password/update-password.component'
import { PlaceOrderComponent } from './place-order/place-order.component';
import { OrderSummaryComponent } from './order-summary/order-summary.component';
import { ThankyouComponent } from './thankyou/thankyou.component';
import { FaqComponent } from './faq/faq.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { TermAndConditionComponent } from './term-and-condition/term-and-condition.component';
import { ServicesListComponent } from './services-list/services-list.component';
import { DryCleaningComponent } from './services-list/dry-cleaning/dry-cleaning.component';
import { ContactusComponent } from './contactus/contactus.component';
import { AuthGuard } from '../app/authGuard';
import { PaymentComponent } from './payment/payment.component';
import { AgentSignupComponent } from './agent-signup/agent-signup.component'

const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'login', component: LoginComponent },
  { path: 'signup', component: SignupComponent },
  { path: 'agent-signup', component: AgentSignupComponent },
  { path: 'forgot-password', component: ForgotpasswordComponent },
  { path: 'reset-password', component: UpdatePasswordComponent },
  { path: 'price', component: PriceComponent },
  { path: 'business', component: BusinessComponent },
  { path: 'profile', component: ProfileComponent , canActivate: [AuthGuard]},
  { path: 'address-book', component: AddressbookComponent, canActivate: [AuthGuard] },
  { path: 'myorders', component: MyordersComponent, canActivate: [AuthGuard] },
  { path: 'promo-listing', component: PromolistingComponent, canActivate: [AuthGuard] },
  { path: 'share-referal', component: SharerefferalcodeComponent, canActivate: [AuthGuard] },
  { path: 'order-detail/:id', component: OrderDetailsComponent, canActivate: [AuthGuard] },
  { path: 'place-order', component: PlaceOrderComponent, canActivate: [AuthGuard] },
  { path: 'order-summary', component: OrderSummaryComponent, canActivate: [AuthGuard] },
  { path: 'thank-you', component: ThankyouComponent, canActivate: [AuthGuard] },
  { path: 'faq', component: FaqComponent },
  { path: 'privacy-policy', component: PrivacyPolicyComponent },
  { path: 'terms-conditions', component: TermAndConditionComponent },
  { path: 'services', component: ServicesListComponent },
  // {path:'dry-cleaning',component:DryCleaningComponent},
  { path: 'contactus', component: ContactusComponent },
  { path: 'payment', component: PaymentComponent, canActivate: [AuthGuard] },
  { path: '**', component: LoginComponent }

];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    anchorScrolling: 'enabled',
    scrollPositionRestoration: 'enabled',
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
