import { Component, OnInit } from '@angular/core';
import { CommonService } from '../services/common.service';

@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.css']
})
export class PrivacyPolicyComponent implements OnInit {
  privacy_policy:any
  constructor(private commonService: CommonService) { }

  ngOnInit() {
    this.getPrivacyPolicy()
  }

  getPrivacyPolicy() {
    if(localStorage.getItem('atlas$%^token')){
      this.commonService.callApiFunction('/settings/setting', 'get', 'null')
      .subscribe(result => {
        this.privacy_policy = result['data']['legal'].find(data=> data.title==='PRIVACY_POLICY')
      }, error => {
        this.privacy_policy = ""
      })
    }else{
      this.commonService.callApiFunction('/settings/setting/setting-website', 'get', 'null')
      .subscribe(result => {
        this.privacy_policy = result['data']['legal'].find(data=> data.title==='PRIVACY_POLICY')
      }, error => {
        this.privacy_policy = ""
      })
    }
  }

}
