import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatStepperModule } from '@angular/material/stepper';
import {
  MatButtonModule,
  MatFormFieldModule,
  MatInputModule,
  MatRippleModule,
  MatPaginatorModule,
  MatProgressBarModule
} from '@angular/material';
import { GooglePlaceModule } from "ngx-google-places-autocomplete";
import { MatRadioModule } from '@angular/material/radio';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { AppRoutingModule } from './app-routing.module';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { AppComponent } from './app.component';
import { HeaderComponent } from './common/header/header.component';
import { FooterComponent } from './common/footer/footer.component';
import { HomeComponent } from './home/home.component';
import { PriceComponent } from './price/price.component';
import { BusinessComponent } from './business/business.component';
import { ProfileComponent } from './profile/profile.component';
import { AddressbookComponent } from './addressbook/addressbook.component';
import { MyordersComponent } from './myorders/myorders.component';
import { PromolistingComponent } from './promolisting/promolisting.component';
import { SharerefferalcodeComponent } from './sharerefferalcode/sharerefferalcode.component';
import { OrderDetailsComponent } from './order-details/order-details.component';
import { LocationComponent } from './location/location.component';
import { LoginComponent } from './login/login.component';
import { SignupComponent } from './signup/signup.component';
import { ForgotpasswordComponent } from './forgotpassword/forgotpassword.component';
import { PlaceOrderComponent } from './place-order/place-order.component';
import { OrderSummaryComponent } from './order-summary/order-summary.component';

import { ThankyouComponent } from './thankyou/thankyou.component';
import { FaqComponent } from './faq/faq.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { TermAndConditionComponent } from './term-and-condition/term-and-condition.component';
import { ServicesListComponent } from './services-list/services-list.component';
import { DryCleaningComponent } from './services-list/dry-cleaning/dry-cleaning.component';
import { SpecialisedCleaningComponent } from './services-list/specialised-cleaning/specialised-cleaning.component';
import { WeddingGownComponent } from './services-list/wedding-gown/wedding-gown.component';
import { HatsAkubraComponent } from './services-list/hats-akubra/hats-akubra.component';
import { LeatherSuedesComponent } from './services-list/leather-suedes/leather-suedes.component';
import { CostumesComponent } from './services-list/costumes/costumes.component';
import { UniformsComponent } from './services-list/uniforms/uniforms.component';
import { HouseholdItemsComponent } from './services-list/household-items/household-items.component';
import { CommercialLinenComponent } from './services-list/commercial-linen/commercial-linen.component';
import { ContactusComponent } from './contactus/contactus.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TokenInterceptor } from '../app/services/token.interceptor';
import { GooglePlaceDirective } from './directive/google-place.directive';
import { PaymentComponent } from './payment/payment.component';
import { UpdatePasswordComponent } from './update-password/update-password.component';
import { BarRatingModule } from 'ngx-bar-rating';
import { AgentSignupComponent } from './agent-signup/agent-signup.component'

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    HomeComponent,
    PriceComponent,
    BusinessComponent,
    ProfileComponent,
    AddressbookComponent,
    MyordersComponent,
    PromolistingComponent,
    SharerefferalcodeComponent,
    OrderDetailsComponent,
    LocationComponent,
    LoginComponent,
    SignupComponent,
    ForgotpasswordComponent,
    PlaceOrderComponent,
    OrderSummaryComponent, ThankyouComponent, FaqComponent, PrivacyPolicyComponent, TermAndConditionComponent, ServicesListComponent, DryCleaningComponent, SpecialisedCleaningComponent, WeddingGownComponent, HatsAkubraComponent, LeatherSuedesComponent, CostumesComponent, UniformsComponent, HouseholdItemsComponent, CommercialLinenComponent, ContactusComponent, GooglePlaceDirective, PaymentComponent, UpdatePasswordComponent, AgentSignupComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    MatCheckboxModule,
    BrowserAnimationsModule,
    OwlDateTimeModule, OwlNativeDateTimeModule,
    FormsModule,
    ReactiveFormsModule,
    MatStepperModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatRippleModule,
    MatSnackBarModule,
    MatRadioModule,
    SlickCarouselModule,
    GooglePlaceModule,
    HttpClientModule,
    MatPaginatorModule,
    MatProgressBarModule,
    BarRatingModule
  ],
  providers: [{
    provide: HTTP_INTERCEPTORS,
    useClass: TokenInterceptor,
    multi: true
  }],
  bootstrap: [AppComponent]
})
export class AppModule { }
