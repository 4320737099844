import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sharerefferalcode',
  templateUrl: './sharerefferalcode.component.html',
  styleUrls: ['./sharerefferalcode.component.css']
})
export class SharerefferalcodeComponent implements OnInit {

  constructor() { }

  ngOnInit() { 
  }


}
