import { Component, OnInit } from '@angular/core';
import { CommonService } from '../services/common.service';
import { SnackService } from '../services/snackbar.service';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';

declare const $
@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.css']
})
export class SignupComponent implements OnInit {
  signupForm: FormGroup
  address: any = {}
  userType: any = "RETAILER"
  email: any = ""
  otp : any =  ""
  errorMessage: any = ""
  constructor(private router: Router, private commonService: CommonService, private formbuilder: FormBuilder, private snackService: SnackService) { }

  ngOnInit() {
    this.createLoginForm()
  }

  handleAddressChange(e) {
    this.address = e
  }

  createLoginForm() {
    this.signupForm = this.formbuilder.group({
      userName: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required]),
      phone: new FormControl('', [Validators.required]),
      password: new FormControl('', [Validators.required]),
      confirm_password: new FormControl('', [Validators.required]),
      deviceId: new FormControl('string', [Validators.required]),
      fcmToken: new FormControl('string', [Validators.required])
    })
  }

  getUserType(event) {
    this.userType = event.target.value
  }

  signUp() {
    if (this.signupForm.value.password !== this.signupForm.value.confirm_password)
      return this.snackService.openSnackBar('Password and confirm password not match', null)
    else if (this.signupForm.invalid)
      return this.snackService.openSnackBar('Please send valid data', null)
    this.email = this.signupForm.value.email
    this.commonService.callApiFunction('/api/user/register', 'post',
      {
        name: {
          firstName: 'User',
          lastName: 'string',
          userName: this.signupForm.value.userName
        },
        auth: {
          email: this.signupForm.value.email,
          password: this.signupForm.value.password
        },
        phone: this.signupForm.value.phone,
        address: { ...this.address, type: 'HOME' },
        userType: this.userType,
        deviceId: 'string',
        fcmToken: 'string'
      })
      .subscribe(data => {
        if (data['status'] == true) {
          // localStorage.setItem('atlas$%^token', data['data']['token'])
          // this.router.navigate(['/price'])
          $("#change-password").modal("show")
        } else {
          this.snackService.openSnackBar(data['message'], null)
        }
      }, error => {
        this.snackService.openSnackBar(error.message, null)
      })
  }

  setOtp(event) {
      this.otp = event.target.value
  }

  verifyOTP() {
    this.commonService.callApiFunction('/api/user/verify', 'post',
      {
        email: this.email,
        otp: this.otp,
        deviceId: 'string',
        fcmToken: 'string'
      })
      .subscribe(data => {
        if (data['status'] == true) {
          localStorage.setItem('atlas$%^token', data['data']['token'])
          this.router.navigate(['/price'])
          $("#change-password").modal("hide")
        } else {
          this.errorMessage = data['message']
        }
      }, error => {
        this.errorMessage = error.message
      })
  }

  resendOTP() {
    this.commonService.callApiFunction('/api/user/resendOtp', 'post',
      {
        email: this.email
      })
      .subscribe(data => {
        if (data['status'] == true) {
          this.errorMessage = 'OTP has resend to your registered email plz verify OTP to sign up/register'
        } else {
          this.errorMessage = data['message']
        }
      }, error => {
        this.errorMessage = error.message
      })
  }

}
