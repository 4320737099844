import { Component, OnInit, ViewChild } from '@angular/core';
import { CommonService } from '../../app/services/common.service'
import { SnackService } from '../../app/services/snackbar.service'
import { from } from 'rxjs';

@Component({
  selector: 'app-addressbook',
  templateUrl: './addressbook.component.html',
  styleUrls: ['./addressbook.component.css']
})
export class AddressbookComponent implements OnInit {
  addresses: any = []
  length: any = 0
  typeValue: any = 'HOME'
  types: any = [{ name: 'Home', value: 'HOME', isDisabled: false }, { name: 'Work', value: 'WORK', isDisabled: false }, { name: 'Other', value: 'OTHER', isDisabled: false }]

  constructor(private commonService: CommonService, private snackService: SnackService) { }
  ngOnInit() {
    this.getAddress()
  }

  handleAddressChange(e, index) {
    this.addresses[index] = { ...e, type: this.typeValue }
  }

  getAddress() {
    this.commonService.callApiFunction('/api/user/get-address', 'get', null)
      .subscribe(result => {
        this.addresses = result['data']
        for (let i = 0; i < result['data'].length; i++) {
          if (result['data'][i].type == 'HOME') {
            this.types[0].isDisabled = true
          } else if (result['data'][i].type == 'WORK') {
            this.types[1].isDisabled = true
          }
        }
      }, error => {
        this.addresses = []
      })
  }

  addMore() {
    this.addresses = [...this.addresses, {
      "geo": [],
      "type": "OTHER",
      "streetAddress": "",
      "formattedAddress": "",
      "country": "",
      "postalCode": "",
      "landmark": "",
      "locality": "",
    }]
  }

  deleteAddress(index) {
    delete this.addresses[index];
    this.addresses = this.addresses.filter(address => {
      if (address) return address
    });
    this.commonService.callApiFunction('/api/user/add-address', 'post', { address: this.addresses })
      .subscribe(result => {
        this.snackService.openSnackBar('Deleted Successfully', null)
        this.getAddress()
      }, error => {
        return this.snackService.openSnackBar(error.message, null)
      })
  }

  changeAddressType(e) {
    this.typeValue = e.target.value
  }

  changeTypeWhenUpdateAddress(index) {
    this.typeValue = this.addresses[index].type
  }


  updateAddress(i) {
    if (this.addresses[i].formattedAddress == "") {
      return this.snackService.openSnackBar('Please select a valid address', null)
    }
    this.commonService.callApiFunction('/api/user/add-address', 'post', { address: this.addresses })
      .subscribe(result => {
        this.snackService.openSnackBar('Updated Successfully', null)
        this.getAddress()
      }, error => {
        return this.snackService.openSnackBar(error.message, null)
      })
  }

}
