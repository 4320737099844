import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-household-items',
  templateUrl: './household-items.component.html',
  styleUrls: ['./household-items.component.css']
})
export class HouseholdItemsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
