import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CommonService } from '../services/common.service';
import { SnackService } from '../services/snackbar.service';
import { PageEvent } from '@angular/material';

declare const $;
@Component({
  selector: 'app-myorders',
  templateUrl: './myorders.component.html',
  styleUrls: ['./myorders.component.css'],

})
export class MyordersComponent implements OnInit {
  constructor(private _formBuilder: FormBuilder, private commonService: CommonService, private snackService: SnackService) { }
  isLinear = false;
  tracking = false;
  status = 'PENDING,WASHING,WASHED,LINEN,REACHED TO DELIVER LINEN,ACCEPTED,REACHED TO PICKUP LINEN,LINEN PICKED UP,READY FOR DELIVERY,OUT FOR DELIVERY'
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  thirdFormGroup: FormGroup;
  pendingOrders: any = []
  completedOrders: any = []
  cancelledOrders: any = []
  errorMessage: any = ""
  isNoDataFound: Boolean = false
  ratingObject: any = {
    type: 'AGENT',
    bookingId: "",
    body: "",
    rating: {
      given: ""
    }
  }
  pager = {
    totalItems: 0,
    currentPage: 1,
    pageSize: 10,
    totalPages: 10,
    startPage: 1,
    endPage: 10,
    startIndex: 1,
    endIndex: 10,
    pages: []
  };
  length = 100;
  pageSize = 10;
  pageSizeOptions: number[] = [5, 10, 25, 100];
  searchObj = {
    search: '',
    pageNumber: 1,
    noOfRecord: 10
  };
  pageEvent: PageEvent;

  @ViewChild('stepper', { static: true }) stepper;
  changeStep(index: number) {
    this.stepper.selectedIndex = index;
  }

  setPage(page: PageEvent): PageEvent {
    this.searchObj.pageNumber = Number(page.pageIndex + 1);
    this.searchObj.noOfRecord = Number(page.pageSize);
    this.getPendingOrders(this.status);
    return;
  }

  ngOnInit() {
    this.firstFormGroup = this._formBuilder.group({
      firstCtrl: ['', Validators.required]
    });
    this.secondFormGroup = this._formBuilder.group({
      secondCtrl: ['', Validators.required]
    });
    this.thirdFormGroup = this._formBuilder.group({
      thirdCtrl: ['', Validators.required]
    });

    this.getPendingOrders(this.status)
  }

  getPendingOrders(status) {
    this.commonService.callApiFunction('/inventory/order?status=' + this.status, 'get', null)
      .subscribe(result => {
        console.log("result", result)
        if (this.status == 'COMPLETED') {
          this.isNoDataFound = false
          this.completedOrders = result['data']
        } else if (this.status == 'CANCELLED') {
          this.isNoDataFound = false
          this.cancelledOrders = result['data']
        } else {
          this.isNoDataFound = false
          this.pendingOrders = result['data']
        }
        this.pager = this.commonService.getPager(Number(result['totalRecords']), Number(result['currentPage']));
        this.length = this.pager.totalItems;
        this.pageSize = this.pager.pageSize;
      }, error => {
        this.isNoDataFound = true
        this.pendingOrders = []
      })
  }

  selectStatus(status) {
    this.status = status
    this.getPendingOrders(status)
  }

  cancelOrder(id) {
    this.commonService.callApiFunction('/inventory/order', 'put', { orderId: id, status: 'CANCELLED' })
      .subscribe(result => {
        this.getPendingOrders(this.status)
        this.snackService.openSnackBar('Order Cancelled Successfully', null)
      }, error => {
        this.snackService.openSnackBar(error.message, null)
      })
  }


  checkAlreadyRated(orderId) {
    this.ratingObject['bookingId'] = orderId
    this.commonService.callApiFunction('/rating/rating/already-rated?orderId=' + orderId, 'get', null)
      .subscribe(result => {
        if (result['data']['rating']) {
          return this.snackService.openSnackBar('You already rated for this order', null)
        } else {
          this.errorMessage = ""
          this.ratingObject['rating']['given']=""
          this.ratingObject['body']=""
          $('#ratingmodal').modal('show')
        }
      }, error => {
        this.snackService.openSnackBar(error.message, null)
      })
  }

  getStar(event) {
    this.ratingObject['rating']['given'] = event
  }

  getRatingBody(event) {
    this.ratingObject['body'] = event.target.value
  }

  saveRating() {
    if (!this.ratingObject['rating']['given'] || !this.ratingObject['body']) return this.errorMessage = 'Please fill star rating and review first'
    this.commonService.callApiFunction('/rating/rating', 'post', this.ratingObject)
      .subscribe(result => {
        this.snackService.openSnackBar(result['message'], null)
        $('#ratingmodal').modal('hide')
      }, error => {
        this.snackService.openSnackBar(error.message, null)
      })
  }


}
