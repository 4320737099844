import { Component, OnInit } from '@angular/core';
import { CommonService } from '../services/common.service';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { SnackService } from '../services/snackbar.service';

@Component({
  selector: 'app-agent-signup',
  templateUrl: './agent-signup.component.html',
  styleUrls: ['./agent-signup.component.css']
})
export class AgentSignupComponent implements OnInit {
  signupForm: FormGroup;

  constructor(private commonService: CommonService,
    private formbuilder: FormBuilder,
    private router: Router,
    private snackService: SnackService) { }

  ngOnInit() {
    this.signupForm = this.formbuilder.group({
      email: ['', [Validators.required, Validators.email]],
      name: ['', Validators.required],
      age: ['', Validators.required],
      phone: ['', Validators.required],
      location: ['', Validators.required],
      description: ['', Validators.required]
    });
  }

submitForm(){
  if(this.signupForm.invalid) return this.snackService.openSnackBar('Please Fill valid value in all field', null);
  return this.snackService.openSnackBar('Request has sent to admin, Admin will send you further details!', null);
}

}
