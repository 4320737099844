import { Component, OnInit } from '@angular/core';
import { FormControl, FormBuilder, FormGroup, Validators } from '@angular/forms'
import { CommonService } from '../../app/services/common.service'
import { Router } from '@angular/router';
import { SnackService } from '../services/snackbar.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  constructor(private commonService: CommonService,
    private formbuilder: FormBuilder,
    private router: Router,
    private snackService: SnackService) { }
  loginform: FormGroup;
  submitted = false;
  errorMessage = ""
  cartCount:any = 0
  ngOnInit() {
    this.createform()
    this.getCartCount()
  }

  createform() {
    this.loginform = this.formbuilder.group({
      username: ['user@relinns.com', [Validators.required, Validators.email]],
      password: ['12345678', Validators.required],
      loginType: ['USER', Validators.required],
      deviceId: ['string', Validators.required],
      fcmToken: ['string', Validators.required]
    });
  }


  getCartCount() {
    this.commonService.callApiFunction('/inventory/order/cart-count', 'get', null)
      .subscribe(data => {
        this.cartCount = data['data']
        this.commonService.updateCartCounter(data['data']);
      },
        error => {
          this.cartCount = 0
        })
  }

  submitform() {
    debugger;
    console.log(this.loginform.value);
    this.submitted = true;
    if (this.loginform.invalid) return this.snackService.openSnackBar('Please Fill all required field', null);
    this.commonService.callApiFunction('/api/auth/login', 'post', this.loginform.value)
      .subscribe(data => {
        if (data['status'] == true) {
          localStorage.setItem('atlas$%^token', data['data']['token'])
          this.commonService.updateToken(data['data']['token'])
          this.commonService.updateProfile({userName:data['data']['userName'] ? data['data']['userName'] : "", picture: data['data']['picture'] ? data['data']['picture'] : ""});
          this.router.navigate(['/price'])
          //window.location.reload()
        } else {
          this.snackService.openSnackBar(data['message'], null)
        }
      },
        error => {
          this.snackService.openSnackBar(error.message, null)
        })
  }


}
