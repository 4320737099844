import { Component, OnInit } from '@angular/core';
import { CommonService } from '../services/common.service';
import { SnackService } from '../services/snackbar.service';
import { PageEvent } from '@angular/material';

@Component({
  selector: 'app-promolisting',
  templateUrl: './promolisting.component.html',
  styleUrls: ['./promolisting.component.css']
})
export class PromolistingComponent implements OnInit {
  promos: any = []
  constructor(private commonService: CommonService, private snackService: SnackService) { }
  currentPage: any = 1
  numPerPage: any = 10
  pager = {
    totalItems: 0,
    currentPage: 1,
    pageSize: 10,
    totalPages: 10,
    startPage: 1,
    endPage: 10,
    startIndex: 1,
    endIndex: 10,
    pages: []
  };
  length = 100;
  pageSize = 10;
  pageSizeOptions: number[] = [5, 10, 25, 100];
  searchObj = {
    search: '',
    pageNumber: 1,
    noOfRecord: 10
  };
  pageEvent: PageEvent;
  
  ngOnInit() {
    this.getPromos()
  }


  setPage(page: PageEvent): PageEvent {
    this.searchObj.pageNumber = Number(page.pageIndex + 1);
    this.searchObj.noOfRecord = Number(page.pageSize);
    this.getPromos();
    return;
  }

  getPromos() {
    this.commonService.callApiFunction('/promo/promo', 'get', null)
      .subscribe(data => {
        this.promos = data['data']
        this.pager = this.commonService.getPager(Number(data['totalRecords']), Number(data['currentPage']));
        this.length = this.pager.totalItems;
        this.pageSize = this.pager.pageSize;
      }, error => {
        this.snackService.openSnackBar(error.message, null)
      })
  }

}
