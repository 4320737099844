import { Component, OnInit } from '@angular/core';
import { CommonService } from '../services/common.service';

@Component({
  selector: 'app-term-and-condition',
  templateUrl: './term-and-condition.component.html',
  styleUrls: ['./term-and-condition.component.css']
})
export class TermAndConditionComponent implements OnInit {
  terms_and_condition: any
  constructor(private commonService: CommonService) {

  }

  ngOnInit() {
    this.getPrivacyPolicy()
  }

  getPrivacyPolicy() {
    if (localStorage.getItem('atlas$%^token')) {
      this.commonService.callApiFunction('/settings/setting', 'get', 'null')
        .subscribe(result => {
          this.terms_and_condition = result['data']['legal'].find(data => data.title === 'TERMS_AND_CONDITIONS')
        }, error => {
          this.terms_and_condition = ""
        })
    } else {
      this.commonService.callApiFunction('/settings/setting/setting-website', 'get', 'null')
        .subscribe(result => {
          this.terms_and_condition = result['data']['legal'].find(data => data.title === 'TERMS_AND_CONDITIONS')
        }, error => {
          this.terms_and_condition = ""
        })
    }
  }

}
