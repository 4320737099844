import { Component, OnInit, ViewChild } from '@angular/core';
import { MessageService } from '../../app/services/message.service'
import { CommonService } from '../../app/services/common.service'
import { Router, ActivatedRoute } from '@angular/router';
import { SnackService } from '../services/snackbar.service';

declare var $: any;
@Component({
  selector: 'app-place-order',
  templateUrl: './place-order.component.html',
  styleUrls: ['./place-order.component.css']
})
export class PlaceOrderComponent implements OnInit {
  cartData: any = {};
  netTotal: any
  addresses: any = []
  pickupAddress: any
  spinner1 = false;
  spinner2 = false;
  deliveryAddress: any
  id: any = "";
  subscribe$: any;
  promos: any = []
  newCartValue: any = 0
  discountedAmount: any = 0
  isProceed: boolean = true
  cards: any = []
  isEmpty: boolean = false
  cardData: any = {}
  cvv: any = ""
  selectedPromo: any = ""
  deliveryCharge: any;
  constructor(
    private route: ActivatedRoute,
    private messageService: MessageService,
    private commonService: CommonService,
    private snackService: SnackService,
    private router: Router
  ) {
    this.subscribe$ = this.messageService.getMessage()
      .subscribe((message: any) => {
        if (message) {
          this.cartData = localStorage.getItem('cartData') ? JSON.parse(localStorage.getItem('cartData')) : {};
          console.log("ggggggggggggggg",  this.cartData['deliveryCharge'].totalAmount)
          this.deliveryCharge = this.cartData['deliveryCharge'].totalAmount
          this.netTotal = this.cartData['price'].totalAmount + this.cartData['tax'].totalAmount + this.cartData['deliveryCharge'].totalAmount
          console.log("+++++++++++++++++++", this.netTotal)
        }
      });
  }

  public selectedMoment = new Date();
  minDate: Date = new Date();

  showSpinner(type) {
    if (type == 'PICKUP') {
      this.spinner1 = true;
    } else {
      this.spinner2 = true;
    }
  }

  handleAddressChange(e, type) {
    console.log("++++++++++++", e)
    if (type == 'PICKUP') {
      if (e.postalCode) {
        this.commonService.callApiFunction('/inventory/serviceArea/checkServiceAvalablity', 'post', { postalCode: e.postalCode })
          .subscribe(result => {
            if (result['data']) {
              let getCart = localStorage.getItem('cartData') ? JSON.parse(localStorage.getItem('cartData')) : {}
              getCart['deliveryCharge'] = result['data']['deliveryCharge']
              console.log("+++++++++++", getCart, result['data']['deliveryCharge'])
              localStorage.setItem('cartData', JSON.stringify(getCart))
              this.pickupAddress = e
              this.spinner1 = false
            } else {
              return this.snackService.openSnackBar('Service is not available in this area right now!', null)
              this.spinner1 = false
            }
          }, error => {
            return this.snackService.openSnackBar('Service is not available in this area right now!', null)
            this.spinner1 = false
          })
      } else {
        return this.snackService.openSnackBar('Service is not available in this area right now!', null)
        this.spinner1 = false
      }
    } else if (type == 'DELIVERY') {
      if (e.postalCode) {
        this.commonService.callApiFunction('/inventory/serviceArea/checkServiceAvalablity', 'post', { postalCode: e.postalCode })
          .subscribe(result => {
            if (result['data']) {
              let getCart = localStorage.getItem('cartData') ? JSON.parse(localStorage.getItem('cartData')) : {}
              getCart['deliveryCharge'] = result['data']['deliveryCharge']
              console.log("+++++++++++", getCart, result['data']['deliveryCharge'])
              localStorage.setItem('cartData', JSON.stringify(getCart))
              this.deliveryAddress = e
              this.spinner2 = false
            } else {
              return this.snackService.openSnackBar('Service is not available in this area right now!', null)
              this.spinner1 = false
            }
          }, error => {
            return this.snackService.openSnackBar('Service is not available in this area right now!', null)
            this.spinner1 = false
          })
      } else {
        return this.snackService.openSnackBar('Service is not available in this area right now!', null)
        this.spinner1 = false
      }
    }
  }

  ngOnInit() {
    this.getAddress()
    this.getAllCards()
  }

  selectAddress(e, type) {
    if (type == 'PICKUP') {
      if (e.value.postalCode) {
        this.commonService.callApiFunction('/inventory/serviceArea/checkServiceAvalablity', 'post', { postalCode: e.value.postalCode })
          .subscribe(result => {
            if (result['data']) {
              let getCart = localStorage.getItem('cartData') ? JSON.parse(localStorage.getItem('cartData')) : {}
              getCart['deliveryCharge'] = result['data']['deliveryCharge']
              console.log("+++++++++++", getCart, result['data']['deliveryCharge'])
              localStorage.setItem('cartData', JSON.stringify(getCart))
              this.pickupAddress = JSON.parse(e.value)
              delete this.pickupAddress['type']
            } else {
              return this.snackService.openSnackBar('Service is not available in this area right now!', null)
            }
          }, error => {
            return this.snackService.openSnackBar('Service is not available in this area right now!', null)
          })
      } else {
        return this.snackService.openSnackBar('Service is not available in this area right now!', null)
      }
    } else {
      if (e.value.postalCode) {
        this.commonService.callApiFunction('/inventory/serviceArea/checkServiceAvalablity', 'post', { postalCode: e.value.postalCode })
          .subscribe(result => {
            if (result['data']) {
              this.deliveryAddress = JSON.parse(e.value)
              delete this.deliveryAddress['type']
            } else {
              return this.snackService.openSnackBar('Service is not available in this area right now!', null)
            }
          }, error => {
            return this.snackService.openSnackBar('Service is not available in this area right now!', null)
          })
      } else {
        return this.snackService.openSnackBar('Service is not available in this area right now!', null)
      }
    }
  }

  showdetail() {
    $('#contactdetaillink').trigger('click');
  }
  showsummery() {
    $('#summerylink').trigger('click');
  }
  showpayment() {
    $('#paymentlink').trigger('click');
  }

  getAddress() {
    this.commonService.callApiFunction('/api/user/get-address', 'get', null)
      .subscribe(result => {
        this.addresses = result['data']
      }, error => {
        this.addresses = []
      })
  }

  getAllPromos() {
    this.commonService.callApiFunction('/promo/promo', 'get', null)
      .subscribe(data => {
        this.promos = data['data']
      }, error => {
        this.promos = []
      })
  }

  getAllCards() {
    this.commonService.callApiFunction('/payment/card', 'get', null)
      .subscribe(data => {
        if (data['status'] == true && data['data'].length) {
          this.cards = data['data']
        } else {
          this.isEmpty = true
          this.cards = []
        }
      }, error => {
        this.isEmpty = true
        this.cards = []
      })
  }

  applyPromo(promoCode) {
    this.selectedPromo = promoCode
    this.commonService.callApiFunction('/promo/promo/verify', 'post', { promoCode: promoCode, oldCartValue: this.cartData ? this.cartData.price.totalAmount.toString() : "0", units: this.cartData ? this.cartData.price.units : 'AUD' })
      .subscribe(data => {
        if (data['status']) {
          $('#selectcategory').modal('hide')
          this.newCartValue = data['data'].newCartValue + this.cartData['tax'].totalAmount + this.cartData['deliveryCharge'].totalAmount
          this.discountedAmount = data['data'].discountAmount
        } else {
          this.newCartValue = 0
          this.discountedAmount = 0
        }
      }, error => {
        this.newCartValue = 0
        this.discountedAmount = 0
      })
  }

  acceptTermAndCondition() {
    this.isProceed = false
  }

  getCardData(event) {
    this.cardData[event.target.name] = event.target.value
  }

  selectCardData(data) {
    this.cardData['cvv'] = ""
    this.cardData['number'] = data.number
    this.cardData['expMonth'] = String(data.expire.month)
    this.cardData['expYear'] = String(data.expire.year)
    this.cardData['cardHolderName'] = data.holder
  }

  saveCardCvv(event) {
    this.cardData['cvv'] = event.target.value
    $('selectCvv').modal('hide')
    this.snackService.openSnackBar('Card Selected Successfully', null)
  }

  isEmptyObject(obj) {
    return (obj && (Object.keys(obj).length === 0));
  }

  payByCard() {
    let isBool = this.isEmptyObject(this.cardData)
    if (!isBool) {
      this.commonService.callApiFunction('/payment/stripe/token', 'post', this.cardData)
        .subscribe(data1 => {
          let token = data1['data'].id
          this.commonService.callApiFunction('/payment/stripe/pay', 'post', { tokenId: token, money: String(this.newCartValue ? this.newCartValue : this.cartData.price.totalAmount), currencyUnits: this.cartData ? this.cartData.price.units : 'AUD' })
            .subscribe(data2 => {
              let transactionId = data2['data'].balance_transaction
              this.commonService.callApiFunction('/inventory/order', 'post',
                { transactionId: transactionId, cardId: '507f1f77bcf86cd799439011', cartId: this.cartData ? this.cartData.cartId : "", pickupAddress: this.pickupAddress, deliveryAddress: this.deliveryAddress, totalPrice: { totalAmount: String((this.newCartValue ? this.newCartValue : this.cartData.price.totalAmount) + this.cartData.tax.totalAmount + this.cartData.deliveryCharge.totalAmount), units: this.cartData ? this.cartData.price.units : 'AUD' }, pickupDate: this.selectedMoment, pickupTime: this.selectedMoment.toLocaleTimeString(navigator.language, { hour: '2-digit', minute: '2-digit' }), deliveryCharge: { totalAmount: String(this.cartData.deliveryCharge.totalAmount), units: this.cartData.deliveryCharge.units }, tax: { totalAmount: String(this.cartData.tax.totalAmount), units: this.cartData.tax.units } })
                .subscribe(data3 => {
                  this.commonService.updateCartCounter(0)
                  this.snackService.openSnackBar('Order Placed Successfully', null)
                  this.router.navigate(['/thank-you'])
                }, error => {
                  this.snackService.openSnackBar(error.message, null)
                })
            }, error => {
              this.snackService.openSnackBar(error.message, null)
            })
        }, error => {
          this.snackService.openSnackBar(error.message, null)
        })
    } else {
      this.snackService.openSnackBar('Please select a card or fill card data', null)
    }
  }

}
